<template>
  <div>
    <v-sheet
      v-if="!categorie.length"
      :color="`grey`"
      class="pa-3"
    >
      <v-skeleton-loader
        class="mx-auto"
        type="table"
      >
      </v-skeleton-loader>
    </v-sheet>
    <v-card v-else>
      <snack-view
        :message="snackbar.message"
        :activate="snackbar.active"
        :bg-color="snackbar.color"
      ></snack-view>
      <v-card-title class="text-uppercase font-weight-bold">
        Affectation des permissions
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form>
          <div
            v-for="cat in categorie"
            v-if="cat.permission.length"
            :key="cat.id"
          >
            <br>
            <h2>{{ cat.name }}</h2>
            <v-spacer></v-spacer>
            <hr>
            <v-row>
              <v-col
                v-for="perm in cat.permission"
                :key="perm.id"
                cols="3"
                sm="12"
                md="4"
                lg="3"
              >
                <v-checkbox
                  v-model="selectedPerms"
                  :label="perm.title"
                  :value="perm.id"
                >
                  {{ perm.id }}
                </v-checkbox>
              </v-col>
            </v-row>
          </div>
          <v-row
            class=""
          >
            <v-col
              cols="12"
              class="d-sm-flex flex-sm-row-reverse"
            >
              <v-btn
                color="primary"
                class="ml-sm-6 col-sm-2 mt-1 mb-2"
                :disabled="loading"
                :loading="loading"
                @click="saveRoleCapacity"
              >
                Enregistrer
              </v-btn>
              <!--              <v-btn-->
              <!--                type="button"-->
              <!--                class="ml-sm-6 col-sm-2 mt-1 mb-2"-->
              <!--                outlined-->
              <!--              >-->
              <!--                Annuler-->
              <!--              </v-btn>-->
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiEyeOutline,
} from '@mdi/js'
import Http from '@/helpers/http'
import Vue from 'vue'
import moment from 'moment'

// eslint-disable-next-line import/extensions
import SnackView from '@/views/SnackView'

Vue.prototype.moment = moment

export default {
  components: { SnackView },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  setup() {
    const rolePermission = ref([])
    const permission = ref([])
    const categorie = ref([])
    const selectedPerms = reactive([])
    const loading = ref(false)

    // eslint-disable-next-line no-shadow

    const snackbar = ref({
      message: '',
      active: '',
      color: 'success',
    })

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiEyeOutline,
      },
      permission,
      rolePermission,
      categorie,
      snackbar,
      loading,
      selectedPerms,
    }
  },
  mounted() {
    moment.locale('fr')
  },
  created() {
    this.getRolePermissions()
    this.getCategorie()
  },
  methods: {

    getRolePermissions() {
      Http.get(`show-role-permission/${this.id}`)
        .then(async response => {
          // eslint-disable-next-line no-multi-assign
          this.rolePermission.value = await response.data
          console.log('rolePermission')
          console.log(this.rolePermission.value)
          this.selectedPerms = Object.entries(response.data).map(arr => (arr[1].id))
          console.log('selectedPerms0')
          console.log(this.selectedPerms)
        })
    },

    saveRoleCapacity() {
      console.log('selectedPerms')
      console.log(this.selectedPerms)
      this.loading = true
      const existing = Object.entries(this.rolePermission.value).map(arr => (arr[1].id))
      let toAdd
      if (existing.length === 0) {
        toAdd = this.selectedPerms
      } else {
        toAdd = this.selectedPerms.filter(item => !existing.includes(item))
      }
      const toRemove = existing.filter(item => !this.selectedPerms.includes(item))

      //
      // console.log('existing', existing)
      // console.log('toAdd', toAdd)
      // console.log('toRemove', toRemove)

      this.snackbar.active = false
      this.snackbar.message = ''
      if (toAdd.length > 0) {
        Http.post(`store-role-permission/${this.id}`, { permission: toAdd })
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.loading = false
            this.snackbar.message = response.message
            this.snackbar.active = true
          })
      }

      if (toRemove.length > 0) {
        this.snackbar.active = false
        this.snackbar.message = ''
        Http.post(`revoke-role-permission/${this.id}`, { permission: toRemove })
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.loading = false
            this.snackbar.message = response.message
            this.snackbar.active = true
          })
      }
    },

    getCategorie() {
      Http.get('permission-categorie')
        .then(async response => {
          // eslint-disable-next-line no-multi-assign
          const cat = await response.data

          Http.get('permission')
            // eslint-disable-next-line no-shadow
            .then(async response => {
              // eslint-disable-next-line no-multi-assign

              const permission = await response.data

              for (let i = 0; i < cat.length; i++) {
                cat[i].permission = []

                for (let j = 0; j < permission.length; j++) {
                  if (permission[j].permission_categorie_id === cat[i].id) {
                    cat[i].permission.push(permission[j])
                  }
                }
              }

              this.categorie = cat
              console.log(this.categorie)
            })
        })
    },

    nomCategorie(id) {
      // eslint-disable-next-line array-callback-return,no-return-assign
      let name = this.categorie.filter(item => name = item.id === id)
      if (name.length === 1) return name[0].name

      return id
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
